<template>
    <div class="device-status-rule">
        <els-bread class="_bread"></els-bread>
        <div class="_header d-header">
            <div>
                <el-button type="primary" size="small" @click="handleAdd">{{ lang.addStatusRule }}</el-button>
                <el-button type="primary" size="small" @click="handleDeviceStatus">{{ lang.statusMaintenance }}</el-button>
            </div>
            <div class="h--search">
                <el-select v-model="dType" :placeholder="lang.deviceType" size="small" clearable>
                    <el-option v-for="item in deviceTypes" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <el-input v-model="searchValue" :placeholder="lang.deviceNameNum" size="small"></el-input>
                <el-cascader :options="deviceStatusTree" filterable clearable size="small" ref="searchCascader"
                    v-model="dStatus" :props="{ label: 'name', children: 'childList', checkStrictly: true }"
                    :placeholder="lang.deviceStatus" @change="searchStatusChange">
                </el-cascader>
                <el-button type="primary" size="small" @click="reloadTable(false)">{{ lang.search }}</el-button>
                <el-button size="small" @click="reloadTable(true)">{{ lang.reset }}</el-button>
            </div>
        </div>
        <div class="_content d-content">
            <el-tabs v-model="currentModel" type="border-card" @tab-click="handleTabClick" class="tabs-content">
                <el-tab-pane :label="lang.byDeviceType" name="1"></el-tab-pane>
                <el-tab-pane :label="lang.byDevice" name="2"></el-tab-pane>
            </el-tabs>
            <div class="table-content">
                <els-table ref="dRefs" :column="tableColumn" :onload="tableOnload" menuWidth="130px" class="expand-table"
                    :table="{ rowKey: 'id', treeProps: { children: 'childrenList' } }" indexType='undefined' idKey="id"
                    :class="{ 'type-expand-table': currentModel == 1 }">
                    <template v-slot:index="{ index, row }">
                     <span>{{ row.parentDeviceStatus ? '' : index + pageSize*(pageNum-1) }}</span>
                    </template>
                    <template v-slot:menu="{ row }">
                        <el-button type="text" @click.stop="handleRowEdit(row)">{{ lang.edit }}
                        </el-button>
                        <el-divider direction="vertical"></el-divider>
                        <el-button type="text" @click.stop="handRowDel(row)">{{ lang.delete }}</el-button>
                    </template>
                    <template v-slot:deviceName="{ row }">
                        {{ row.parentDeviceStatus ? '' : (row.deviceName || '') + (row.deviceCode ? '(' + row.deviceCode + ')' :
                            '') }}
                    </template>
                </els-table>
            </div>

        </div>
        <els-form-dialog :visible.sync="dialogVisible" :title="dialogTitle" width="720px" :loading="loadingBtn"
            :cancel="lang.cancel" :submit="lang.confirm" @submit="handleSubmit" class="device-rule-dialog">
            <els-form v-if="dialogVisible" ref="elsFormRef" v-model="formData" :column="formColumn"
                class="device-rule-form" @change-deviceTypeId="changeDeviceType" @change-devicePoint="changeDevicePoint">
                <template v-slot:deviceStatus>
                    <el-select v-model="formData.deviceStatus" disabled :placeholder="lang.deviceStatus" style="width:217px"
                        clearable v-if="formData.id">
                        <el-option v-for="item in deviceStatus" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-cascader ref="formCascader" :options="deviceStatusTree" v-model="formData.deviceStatus" filterable
                        :props="{ label: 'name', children: 'childList', checkStrictly: true }" @change="changeFormCascader"
                        v-else>
                    </el-cascader>
                </template>
                <template v-slot:pointUse>
                    <div class="point-content">
                        <div v-if="formData.devicePoint && formData.devicePoint.length > 0" class="left-point">
                            <el-button size="small" v-for="item in selDevicePoints" :key="item.value"
                                @click="clickPoint(`[${item.value}]`)">
                                <span :title="item.label">{{ item.label }}</span>
                            </el-button>
                        </div>
                        <div v-else class="left-point"></div>
                        <div class="keyboard-content">
                            <div class="key-item" v-for="item in keyboardData" :key="item" @click="clickPoint(item)"
                                :style="(item == '()' || item == '\'\'') ? 'letter-spacing: 2px' : ''">
                                {{ item }}
                            </div>
                        </div>
                    </div>
                </template>
                <template v-slot:formula>
                    <el-input @keydown.native="keydownF" @keyup.native="keyupF" type="textarea"
                        v-model="formData.algorithmFormula" ref="formInput" @focus="focusEvent"
                        @input="inputFormula"></el-input>
                </template>
            </els-form>
        </els-form-dialog>
        <!-- 设备状态维护弹框 -->
        <el-dialog :visible.sync="maintenanceDialog" :title="maintenanceTitle" width="720px" @close="closeStatusDialog">
            <el-button type="primary" size="small" style="margin-bottom: 16px;" @click="handleAddStatus(null)">{{
                lang.addStatus }}</el-button>
            <div class="status-dialog-body">
                <els-table ref="statusRefs" :column="statusTableColumn" :onload="statusTableOnload" menuWidth="200px"
                    :showPagination="false" :table="{ rowKey: 'id', treeProps: { children: 'childList' } }"
                    indexType='undefined' idKey="id" class="expand-table">
                    <template v-slot:index="{ index, row }">
                        <span>{{ row.fatherNode ? '' : index }}</span>
                    </template>
                    <template v-slot:menu="{ row }">
                        <el-button type="text" @click.stop="handleStatusRowEdit(row)">{{ lang.edit }}
                        </el-button>
                        <el-divider direction="vertical"></el-divider>
                        <el-button type="text" @click.stop="handStatusRowDel(row)">{{ lang.delete }}</el-button>
                        <el-divider direction="vertical" v-if="!row.fatherNode"></el-divider>
                        <el-button type="text" @click.stop="handleAddStatus(row)" v-if="!row.fatherNode">{{
                            lang.addSubordinates }}</el-button>
                    </template>
                </els-table>
            </div>
            <div style="text-align:right">
                <el-button size="normal" class="btn-normal" @click="maintenanceDialog = false">{{ lang.cancel }}</el-button>
            </div>
        </el-dialog>
        <!-- 新增状态弹框 -->
        <els-form-dialog :visible.sync="statusDialog" :title="statusTitle" width="600px" :cancel="lang.cancel"
            :loading="statusLoadingBtn" :submit="lang.confirm" @submit="handleStatusSubmit">
            <els-form v-if="statusDialog" ref="statusFormRef" v-model="statusFormData" :column="statusFormColumn">
            </els-form>
        </els-form-dialog>
    </div>
</template>

<script>
import { mapState } from "vuex";
import elsBread from "@/components/els-breadcrumb/main.vue";
import elsTable from "@/components/els-table/main.vue";
import mixinsI18n from "@/mixins/i18n.js";
import elsFormDialog from "@/components/els-form-dialog/main.vue";
import elsForm from "@/components/els-form/main.vue";
import { getDict } from "@/api/com/iot-dict.js";
import {
    getDeviceTypeData,
    getStateRuleList,
    getDeviceNames,
    getDeviceNodes,
    addStateRule,
    updateStateRule,
    delStateRule,
    getStateList,
    addDeviceStatus,
    updateDeviceStatus,
    deleteDeviceStatus,
    getStateDict
} from "@/api/manager/device-status-rule.js"

export default {
    name: "device-status-rule",
    mixins: [mixinsI18n],
    components: { elsBread, elsTable, elsFormDialog, elsForm },
    data: () => {
        return {
            PAGE_NAME: "deviceStatusRulePage",
            columnBread: [],
            deviceTypes: [],
            dType: "",
            searchValue: "",
            deviceStatus: [],
            dStatus: "",
            dialogVisible: false,
            dialogTitle: "",
            loadingBtn: false,
            formData: {},
            keyboardData: ["7", "8", "9", '+', '>', '>=', '==', "4", "5", "6", '-', '<', '<=', '⊆', "1", "2", "3", '*', '||',
                "''", '↶', '%', "0", '.', '/', '&&', '()', 'AC'],
            blurIndex: 0,
            saveWord: '',
            deviceNames: [],
            devicePoints: [],
            selDevicePoints: [],
            isClickPoint: false,
            readonly: false,
            saveFormulaList: [],  // 保存算法公式堆栈
            currentModel: "1", // 当前选择的模块 1按设备类型 2按设备
            maintenanceDialog: false, // 设备状态维护弹窗
            maintenanceTitle: "", // 设备状态维护弹窗名称
            statusDialog: false, // 状态弹框
            statusTitle: "", // 状态弹框名称
            statusLoadingBtn: false, // 状态保存按钮
            statusFormData: {},
            deviceStatusTree: [],  // 设备状态树形结构
            currentDeviceStatus: {}, // 当前选择的设备状态
            pageNum: 1,
            pageSize: 15,
        }
    },
    computed: {
        ...mapState(["breadMenus"]),
        tableColumn() {
            // const level = {
            //     prop: "configLevel",
            //     label: this.lang.confLevel,
            //     dict: [{ value: '1', label: this.lang.byDeviceType }, { value: '2', label: this.lang.byDevice }],
            // };
            const type = { prop: "deviceTypeId", label: this.lang.deviceType, dict: this.deviceTypes };
            const name = { prop: "deviceName", label: this.lang.devNameNum, hasSlot: true };
            const state = { prop: "deviceStatus", label: this.lang.state, dict: this.deviceStatus, width: 130 };
            // 计算方式 1:按公式 2:按父级/同级 3:按子级
            const calcType = {
                prop: "calcType", label: this.lang.calMethod,
                width: 180,
                dict: [{ value: "1", label: this.lang.accFormula }, { value: "2", label: this.lang.byParentSibling }, { value: "3", label: this.lang.byChild }],
            };
            const formula = { prop: "algorithmFormulaCn", label: this.lang.algFormula, minWidth: 130 };
            const returnColumn = this.currentModel == '1' ? [type, state, calcType, formula] : [type, name, state, calcType, formula]
            return returnColumn
        },
        formColumn() {
            const status = {
                tag: "el-form-item",
                label: this.lang.deviceStatus,
                required: true,
                trigger: "change",
                prop: "deviceStatus",
                cls: {
                    tag: 'slot',
                    name: "deviceStatus",
                }
            };
            const level = {
                tag: 'el-radio-group',
                prop: "configLevel",
                label: this.lang.confLevel,
                required: true,
                disabled: this.formData.id ? true : false,
                value: this.currentModel,
                cls: [{ label: '1', cls: this.lang.byDeviceType },
                { label: '2', cls: this.lang.byDevice }],
            };
            const device = this.formData.configLevel == '2' ? {
                tag: 'div',
                class: "line--item",
                cls: [{
                    tag: "el-select",
                    prop: "deviceTypeId",
                    label: this.lang.deviceType,
                    placeholder: this.lang.pleaseSelect,
                    required: true,
                    trigger: "change",
                    disabled: this.formData.id ? true : false,
                    cls: this.deviceTypes
                },
                {
                    tag: "el-select",
                    prop: "deviceCode",
                    label: this.lang.deviceName,
                    placeholder: this.lang.pleaseSelect,
                    style: "width:200px",
                    required: true,
                    disabled: this.formData.id ? true : false,
                    trigger: "change",
                    cls: this.deviceNames
                }]
            } : {
                tag: 'div',
                class: "line--item",
                cls: [{
                    tag: "el-select",
                    prop: "deviceTypeId",
                    label: this.lang.deviceType,
                    placeholder: this.lang.pleaseSelect,
                    required: true,
                    trigger: "change",
                    disabled: this.formData.id ? true : false,
                    cls: this.deviceTypes
                }]
            };
            const calcType = {
                tag: 'el-radio-group',
                prop: "calcType",
                label: this.lang.calMethod,
                required: true,
                value: '1',
                trigger: "change",
                key: this.formData.deviceStatus?.toString() || '',
                cls: this.calcTypeCls,
            };
            const point = {
                tag: 'div',
                class: "line--item",
                cls: [{
                    tag: "el-select",
                    multiple: true,
                    prop: "devicePoint",
                    label: this.lang.pointSel,
                    placeholder: this.lang.pleaseSelect,
                    required: true,
                    collapseTags: true,
                    trigger: "change",
                    cls: this.devicePoints
                },
                {
                    prop: "auxiliaryDuration",
                    label: this.lang.auxDuration,
                    style: "width:200px",
                    rules: [{ pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/, message: this.lang.durMess },
                    {
                        validator: (rule, value, callback) => {
                            if (value && value.toString().length > 10) {
                                callback(new Error(this.lang.max10));
                                return;
                            }
                            callback();
                        }, trigger: 'blur'
                    }
                    ],
                }]
            };
            const used = {
                tag: "el-form-item",
                label: this.lang.avaPoint,
                cls: {
                    tag: 'slot',
                    name: "pointUse",
                }
            };
            const formula = {
                tag: "el-form-item",
                label: this.lang.algFormula,
                prop: "algorithmFormula",
                required: true,
                trigger: "change",
                // rules: [
                //     {
                //         validator: (rule, value, callback) => {
                //             if (value && value.replace(/\s*/g, "").length > 50) {
                //                 callback(new Error(this.lang.max50));
                //                 return;
                //             }
                //             callback();
                //         }, trigger: 'change'
                //     }
                // ],
                cls: {
                    tag: 'slot',
                    name: "formula",
                }
            };
            const returnFormColumn = this.formData.calcType == '1' ? [status, level, device, calcType, point, used, formula] : [status, level, device, calcType]
            return returnFormColumn
        },
        statusTableColumn() {
            const name = { prop: "name", label: this.lang.deviceStatus };
            return [name]
        },
        statusFormColumn() {
            const name = {
                prop: "name",
                label: this.lang.deviceStatus,
                required: true,
                rules: [
                    { max: 50, message: this.lang.max50, trigger: "blur" },
                ],
            };
            return [name]
        },
        calcTypeCls() {
            if (this.formData.id && this.formData.deviceStatus) {
                const currentStatus = this.deviceStatus.find((item) => item.value == this.formData.deviceStatus);
                return [{ label: "1", cls: this.lang.accFormula },
                { label: "2", cls: this.lang.byParentSibling, disabled: (currentStatus.fatherNode) ? false : true },
                {
                    label: "3", cls: this.lang.byChild,
                    disabled: (!currentStatus.fatherNode && currentStatus.childList) ? false : true
                }]
            }
            if (this.formData.deviceStatus) {
                return [{ label: "1", cls: this.lang.accFormula },
                { label: "2", cls: this.lang.byParentSibling, disabled: (this.currentDeviceStatus.fatherNode) ? false : true },
                {
                    label: "3", cls: this.lang.byChild,
                    disabled: (!this.currentDeviceStatus.fatherNode && this.currentDeviceStatus.childList) ? false : true
                }]
            }
            return [{ label: "1", cls: this.lang.accFormula },
            { label: "2", cls: this.lang.byParentSibling }, { label: "3", cls: this.lang.byChild }]
        }
    },
    methods: {
        // 切换tab
        handleTabClick() {
            this.$refs.dRefs && this.$refs.dRefs.apiOnload();
        },
        async tableOnload(page, query) {
            this.pageNum = page.pageNum;
            this.pageSize = page.pageSize;
            try {
                const p = {
                    pageNum: page.pageNum,
                    pageSize: page.pageSize,
                    configLevel: this.currentModel, // 1： 按设备类型 2： 按设备
                    deviceCode: this.searchValue || null,
                    deviceTypeId: this.dType || null,
                    deviceStatus: (this.dStatus.length && this.dStatus.length > 0) ? this.dStatus[this.dStatus.length - 1] : null
                };
                const res = await getStateRuleList(p);
                const list = res.result.data;
                const total = res.result.total || res.result.data.length;
                return { list, total };
            } catch (err) {
                console.log(err);
                return { list: [], total: 0 };
            }
        },
        reloadTable(v) {
            if (v) {
                this.searchValue = "";
                this.dType = "";
                this.dStatus = "";
            }
            this.$refs.dRefs && this.$refs.dRefs.apiOnload();
        },
        handleAdd() {
            this.dialogVisible = true;
            this.formData = {
                deviceCode: "",
                auxiliaryDuration: 0,
                devicePoint: []
            };
            this.saveFormulaList = [];
            this.saveFormulaList.push('');
            this.dialogTitle = this.lang.addDeviceRule;
            this.selDevicePoints = [];
            this.deviceNames = [];
            this.devicePoints = [];
        },
        async handleRowEdit(row) {
            this.dialogVisible = true;
            this.dialogTitle = this.lang.editDeviceRule;
            this.saveFormulaList = [];
            this.selDevicePoints = [];
            this.saveFormulaList.push(row.algorithmFormula);
            const { id, algorithmFormula, auxiliaryDuration, configLevel, deviceCode, calcType, devicePoint, deviceStatus, deviceTypeId } = row;
            this.formData = {
                id,
                algorithmFormula,
                auxiliaryDuration,
                configLevel,
                deviceCode,
                calcType,
                devicePoint,
                deviceStatus,
                deviceTypeId
            };
            this.formData.devicePoint = this.formData.devicePoint?.split(',');
            if (row.configLevel == '2') {
                this.getDeviceNameData(row.deviceTypeId);
            }
            this.getDeviceStatusDict();
            await this.getDevicePointData(row.deviceTypeId);
            this.changeDevicePoint(this.formData.devicePoint, true);
        },
        handRowDel(row) {
            this.$confirm(this.lang.delMsg, this.lang.tips, {
                confirmButtonText: this.lang.confirm,
                cancelButtonText: this.lang.cancel,
                type: "warning",
            })
                .then(() => {
                    this.deleteStateRule(row.id);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        async deleteStateRule(id) {
            try {
                const res = await delStateRule(id);
                this.$message.success(res.msg);
                this.$refs.dRefs.apiOnload();
            } catch (e) {
                console.log(e);
            }
        },
        async handleSubmit() {
            try {
                await this.$refs["elsFormRef"].validate();
                let res;
                const form = { ...this.formData };
                if (!form.id) {
                    form.deviceStatus = (form.deviceStatus.length && form.deviceStatus.length > 0) ? form.deviceStatus[form.deviceStatus.length - 1] : null;
                }
                form.devicePoint = form.devicePoint?.join(",");
                form.deviceCode = form.configLevel == "2" ? form.deviceCode : '';
                const str = form.algorithmFormula || "";
                const reg = new RegExp("\\[|\\]", "g");
                form.algorithmFormulaNotBracket = str.replace(reg, "");
                if (form.id) {
                    res = await updateStateRule({ ...form });
                } else {
                    res = await addStateRule({ ...form });
                }
                this.$message({ type: "success", message: res.msg });
                this.dialogVisible = false;
                this.$refs.dRefs.apiOnload();
            } catch (err) {
                console.log(err);
            }
        },
        changeDeviceType(v) {
            this.formData.deviceCode = '';
            this.formData.devicePoint = [];
            this.getDeviceNameData(v);
            this.getDevicePointData(v);
        },
        // 选择参数改变时
        changeDevicePoint(v, num) {
            this.selDevicePoints = [];
            const arr = [];
            if (v && v.length > 0) {
                v.forEach(item => {
                    const obj = this.devicePoints.find((item1) => item1.value == item);
                    if (obj) {
                        this.selDevicePoints.push(obj);
                        arr.push(obj.value);
                    }
                });
                if (num == true) {
                    this.formData.devicePoint = arr;
                }
            }
        },
        //输入事件
        inputFormula(v) {
            this.saveFormulaList.push(this.formData.algorithmFormula);
        },
        // 键盘输入
        keydownF(v) {
            // this.readonly = true;
        },
        keyupF(v) {
            // this.readonly = false;
        },
        // 设置光标位置
        setCaretPosition(event, pos) {
            this.$nextTick(() => {
                let ctrl = event.target
                if (ctrl.setSelectionRange) {
                    ctrl.focus()
                    ctrl.setSelectionRange(pos, pos)
                }
            })
        },
        // 算法公式输入事件
        focusEvent(e) {
            this.blurIndex = e.srcElement.selectionStart;
            if (!this.isClickPoint) {
                return;
            }
            this.isClickPoint = false;
            if (this.saveWord == 'AC') {
                this.$set(this.formData, "algorithmFormula", '');
                this.saveFormulaList.push('');
                this.setCaretPosition(e, 0);
                return;
            }
            if (this.saveWord == '↶') {
                if (this.saveFormulaList.length > 1) {
                    this.saveFormulaList.pop();
                }
                const data = this.saveFormulaList[this.saveFormulaList.length - 1] || '';
                this.$set(this.formData, "algorithmFormula", data);
                return;
            }
            const str = this.formData.algorithmFormula;
            const data = (this.formData.algorithmFormula && this.formData.algorithmFormula.length > 0) ?
                str.slice(0, this.blurIndex) + this.saveWord + str.slice(this.blurIndex) : this.saveWord;
            this.$set(this.formData, "algorithmFormula", data);
            this.saveFormulaList.push(this.formData.algorithmFormula);
            this.setCaretPosition(e, this.blurIndex + this.saveWord.length);
        },
        clickPoint(i) {
            this.saveWord = i;
            this.isClickPoint = true;
            this.$refs.formInput.focus();
        },
        // 获取设备状态数据
        async getDeviceStatusDict() {
            const res = await getStateDict();
            this.deviceStatus = res.result.data.map(item => ({
                value: item.value, label: item.name,
                childList: item.childList, fatherNode: item.fatherNode
            }));
        },
        // 树状查询设备状态字典
        async getDeviceStatusTree() {
            const res = await getStateList();
            this.deviceStatusTree = res.result;
        },
        async getDeviceType() {
            const res = await getDeviceTypeData();
            this.deviceTypes = res.map(item => ({ value: item.id.id, label: item.deviceTypeName }));
        },
        async getDeviceNameData(v) {
            const res = await getDeviceNames({ deviceTypeId: v });
            this.deviceNames = res.result.map(item => ({ value: item.code, label: item.name }));
        },
        async getDevicePointData(v) {
            const res = await getDeviceNodes({ deviceTypeId: v });
            this.devicePoints = res.result.map(item => ({
                value: item.nameEn,
                label: `${item.nameCn}(${item.nameEn})`
            }));
        },
        // 点击设备状态维护
        handleDeviceStatus() {
            this.maintenanceDialog = true;
            this.maintenanceTitle = this.lang.statusMaintenance;
        },
        // 设备状态表格查询
        async statusTableOnload() {
            try {
                const res = await getStateList();
                const list = res.result;
                return { list };
            } catch (err) {
                console.log(err);
                return { list: [], total: 0 };
            }
        },
        // 新增状态
        handleAddStatus(row) {
            this.statusDialog = true;
            this.statusTitle = row ? `${this.lang.add} ${row.name} ${this.lang.subStatus}` : this.lang.addStatus;
            this.statusFormData = row ? { fatherNode: row.id } : {};
        },
        // 编辑状态
        handleStatusRowEdit(row) {
            this.statusFormData = { id: row.id, name: row.name };
            this.statusDialog = true;
            this.statusTitle = this.lang.editStatus;
        },
        // 删除状态
        handStatusRowDel(row) {
            this.$confirm(this.lang.delMsg, this.lang.tips, {
                confirmButtonText: this.lang.confirm,
                cancelButtonText: this.lang.cancel,
                type: "warning",
            })
                .then(() => {
                    this.deleteStatusData(row.id);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        // 保存状态
        async handleStatusSubmit() {
            try {
                await this.$refs["statusFormRef"].validate();
                let res;
                const form = { ...this.statusFormData };
                if (form.id) {
                    res = await updateDeviceStatus({ ...form });
                } else {
                    res = await addDeviceStatus({ ...form });
                }
                this.$message({ type: "success", message: res.msg });
                this.statusDialog = false;
                this.$refs.statusRefs.apiOnload();
            } catch (err) {
                console.log(err);
            }
        },
        // 删除状态
        async deleteStatusData(id) {
            try {
                const res = await deleteDeviceStatus(id);
                this.$message.success(res.msg);
                this.$refs.statusRefs.apiOnload();
            } catch (e) {
                console.log(e);
            }
        },
        // 搜索状态条件改变事件
        searchStatusChange() {
            this.$refs.searchCascader.toggleDropDownVisible()
        },
        // 表单状态条件改变事件
        changeFormCascader(v) {
            const value = v[v.length - 1];
            this.currentDeviceStatus = this.deviceStatus.find((item) => item.value == value);
            if (this.formData.calcType) {
                // 已选择计算方式是禁用状态时，清空已选择计算方式的值
                const hasSel = this.calcTypeCls.some((item) => item.label == this.formData.calcType && item.disabled);
                if (hasSel) {
                    this.formData.calcType = '';
                }
            }
            this.$refs.formCascader.toggleDropDownVisible();
        },
        // 关闭设备类型维护弹框
        closeStatusDialog() {
            this.getDeviceStatusDict();
            this.getDeviceStatusTree();
        },
        // 获取设备类型名称
        getDeviceTypesName(v) {
            const item = this.deviceTypes.find((item) => item.value == v);
           return item.label
        }
    },
    mounted() {
        this.columnBread = this.breadMenus;
        this.getDeviceStatusDict();
        this.getDeviceStatusTree();
        this.getDeviceType();
    },
}
</script>

<style scoped lang="scss">
.device-status-rule {
    width: 100%;
    height: 100%;

    .d-header {
        padding: 0 24px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;

        .h--search {

            .el-input,
            .el-cascader,
            .el-select {
                width: 200px;
                margin-right: 10px
            }
        }
    }

    .d-content {
        padding: 24px;
        box-sizing: border-box;
        position: relative;
        width: 100%;
        height: calc(100% - 44px - 54px);

        .table-content {
            padding: 16px;
            background-color: #fff;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .12), 0 0 6px 0 rgba(0, 0, 0, .04);
            height: calc(100% - 39px);
            box-sizing: border-box;

            ::v-deep .expand-table {
                .el-table__row--level-1 {
                    td:nth-child(3),
                    td:nth-child(4) {
                        text-indent: 1em
                    }
                    td:nth-child(2) {
                        span {
                            display: none;
                        }
                    }
                }
            }

            ::v-deep .type-expand-table {
                .el-table__row--level-1 {
                    td:nth-child(4) {
                        text-indent: initial;
                    }
                }
            }
        }

        ::v-deep .tabs-content {
            border: 0px;
            border-top: 1px solid #DCDFE6;
            ;

            .el-tabs__content {
                padding: 0;
            }
        }
    }

    ::v-deep .el-button--text:nth-child(3) {
        color: red;
    }
}
.device-rule-dialog {
    ::v-deep  .d--body .b--main {
        max-height: calc(80vh - 197px) !important;
    }
}
.device-rule-form {
    ::v-deep .line--item {
        display: flex;
        justify-content: space-between;

        .el-tag.el-tag--info {
            max-width: 125px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .point-content {
        display: flex;
        justify-content: space-between;

        .left-point {
            flex: 1;
            background: #f4f4f5;
            margin: 4px 0;
            padding-left: 6px;
            height: 160px;
            overflow: hidden auto;

            .el-button {
                max-width: 263px;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-right: 6px;
            }

            .el-button+.el-button {
                margin-left: 0px;
            }
        }

        .keyboard-content {
            display: flex;
            flex-wrap: wrap;
            width: 280px;
            justify-content: flex-end;

            .key-item {
                width: 32px;
                height: 32px;
                background: #CCC;
                margin: 4px 0 4px 8px;
                line-height: 32px;
                text-align: center;
                cursor: pointer;
            }
        }
    }

    ::v-deep .el-select {
        width: 100%;
    }

    ::v-deep .el-textarea__inner {
        font-family: Arial;
        letter-spacing: 2px;
    }

}

.status-dialog-body {
    height: 300px;
    padding-bottom: 24px;

    ::v-deep .el-button--text:nth-child(3) {
        color: red;
    }
}

::v-deep .expand-table {
    .el-table__row--level-1 {
        td:nth-child(2) {
            text-indent: 1em
        }
    }

    .el-tooltip {
        .el-table__expand-icon {
            float: left;
        }

        .el-table__expand-icon+span {
            float: left;
            text-align: left !important;
            width: 33px;
            text-overflow: ellipsis;
            overflow: hidden;
            padding-left: 2px;
        }
    }
}
</style>
